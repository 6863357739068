@import '../../../scss/mixins';

.main-sidebar {
    left: 0;
    width: 280px;
    height: 100vh;
    padding: 8px;
    position: fixed;
    overflow: auto;
    z-index: 9;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  
  //////////##### sidebar close css ///////////#####
  .active-sidebar .sidebar_text {
    font-size: 7px;
    display: flex;
    line-height: 9px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .active-sidebar .pl-\[30px\] {
    padding-left: 0px;
  }
  
  .active-sidebar .space-y-2 li a {
    width: fit-content;
    flex-wrap: wrap;
    gap: 0px;
    justify-content: center;
  }
  
  .active-sidebar .main-sidebar {
    padding: 8px;
    text-align: center;
  }
  
  .active-sidebar .space-y-2 li button a {
    width: fit-content;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .active-sidebar .space-y-2 li button span {
    width: fit-content;
    flex-wrap: wrap;
    display: flex !important;
    justify-content: center;
  }
  
  .active-sidebar .space-y-2 li button span.crm {
    flex-direction: column;
    width: 100%;
  }
  
  .active-sidebar span.text-sm {
    flex-wrap: wrap;
    line-height: 10px;
    gap: 4px;
  }
  
  .active-sidebar .undefined {
    padding: 0px !important;
  }
  
  .active-sidebar .space-y-2 li button svg.h-4.w-4.transition-all.duration-500.text-\[\#7E8B99\] {
    display: none;
  }
  
  .active-sidebar span.text-sm .active {
    flex-wrap: wrap;
    line-height: 10px;
    gap: 0px;
    justify-content: center;
  }
  
  .active-sidebar .space-y-2 li span {
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  
  @keyframes scroll-transition {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .scroll-transition {
    animation: scroll-transition 0.3s ease-out forwards;
  }
  
  span.sidebar_text {
      display: flex;
      gap: 10px;
      align-items: center;
  }