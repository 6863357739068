body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.lineheightcustom{
  line-height: 80px !important;
}

.grianetcolor{
  background: #0052D4;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #6FB1FC, #4364F7, #0052D4);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #6FB1FC, #4364F7, #0052D4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

button[type=submit] {
  -webkit-appearance: button;
  background-color: #ed5627 !important;
  background-image: none;
}

.css-1nmdiq5-menu{
  z-index: 999;
}

.tox .tox-editor-container {
   
  z-index: 1;
}



.custom-scrollbar {
  max-height: calc(9 * 2rem); 
  overflow-y: auto;
}

/* Customize scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ed5627; 
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}


.firstc:first-letter {
  text-transform: uppercase !important;
}

.firstc {
  display: inline-block !important;
}



.ql-container {
  height: 80px !important;
  }
  
  .ql-editor {
    height: 100%;
  }
  
  
  .ql-container.ql-snow {
    height: 100px !important;
    border: 1px solid #e5e7eb !important;
  
  }
  .quills> .quill {
    background: #fff !important;
    border-radius: 10px !important;
    padding-bottom: 13px !important;
  }
  
  .ql-toolbar.ql-snow {
    border: 1px solid #e5e7eb !important;
  }


 .react-datepicker-popper {
    z-index: 9 !important;
    line-height: 0;
}

@media  (max-width: 999px){

  .min-sidebar ~ div > .main-sidebar {
    width: 78px;
    min-width: 68px;
}


/* .main-sidebar.scrollbar.transition-\[width\].duration-300 {
  width: 80px !important;
  min-width: 80px !important;
} */

/* .main-wrapper main.main {
  width: calc(100% - 80px) !important;
} */


/* .w-\[calc\(100\%-280px\)\] {
  width: calc(100% - 80px) !important;
} */

}